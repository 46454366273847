
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders} from '@angular/common/http';  
import { HttpInterceptor } from '@angular/common/http'

import { Observable } from 'rxjs/Observable';  
import 'rxjs/add/operator/map';  
import 'rxjs/add/operator/do';

@Injectable({
  providedIn: 'root'
})

export class AstroAppServiceService {
api = 'https://astrologers.predictforme.com:4050/api/v1/';
  constructor(private http: HttpClient) 
  {

  }

  getToken()
  {
    return localStorage.getItem('token')
  }
  
  authenticate_admin()
  {

    return this.http.post(this.api+'authenticate_admin', {

        },{headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': JSON.parse(localStorage.getItem('auth_app_token')).value
    })});
  }

  intercept(req,next)
  {
    let tokenizedReq = req.clone(
      {
        setHeaders:
        {
          Authorization: `Bearer ${this.getToken()}`
        }
      }
    )
    return next.handle(tokenizedReq)
  }
//astrogers users



getallsecondappusersadmin()
  {
    return this.http.get(this.api + 'getallsecondappusersadmin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }



   edit_secondapp_users_admin(dataObject)
  {
    return this.http.post(this.api + 'edit_secondapp_users_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

   adduserAstrologer(dataObject)
  {
    return this.http.post(this.api + 'adduserAstrologer',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
   delete_secondapp_users_admin(dataObject)
  {
    return this.http.post(this.api + 'delete_secondapp_users_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
     deactivate_secondapp_users_admin(dataObject)
  {
    return this.http.post(this.api + 'deactivate_secondapp_users_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

       activate_secondapp_users_admin(dataObject)
  {
    return this.http.post(this.api + 'activate_secondapp_users_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

      get_particular_secondapp_user_admin(dataObject)
  {
    return this.http.post(this.api + 'get_particular_secondapp_user_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //----Books---//

  addBooksAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_books_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getAllBooksAdmin()
  {
    return this.http.get(this.api + 'get_all_books_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  editBooksAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_books_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  deleteBooksAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_books_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  //---Gemstones---//
  addGemstonesAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_gemstones_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getAllGemstonesAdmin()
  {
    return this.http.get(this.api + 'get_all_gemstones_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  editGemstonesAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_gemstones_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  deleteGemstonesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_gemstones_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //--Events--//
  addEventsAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_events_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  getAllEventsAdmin()
  {
    return this.http.get(this.api + 'get_all_events_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  editEventsAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_events_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  deleteEventsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_events_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getQuestionsForSelectedEventAdmin(dataObject)
  {
    return this.http.post(this.api + 'get_all_questions_for_selected_event_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }
  addQuestionsAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_questions_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  editQuestionsAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_questions_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }
  deleteQuestionsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_questions_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }

  //---Users--//
  getAllWebappUsersAdmin()
  {
    return this.http.get(this.api + 'get_all_webapp_users_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editWebappUsersAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_webapp_users_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteWebappUsersAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_webapp_users_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  activateWebappUsersAdmin(dataObject)
  {
    return this.http.post(this.api + 'activate_webapp_users_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deactivateWebappUsersAdmin(dataObject)
  {
    return this.http.post(this.api + 'deactivate_webapp_users_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //---Clients---//
  getAllClientsAdmin()
  {
    return this.http.get(this.api + 'get_all_advertisement_clients_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addClientsAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_advertisement_clients_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editClientsAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_advertisement_clients_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteClientsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_advertisement_clients_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //---Advertisements---//
  getAllAdvertisementsAdmin()
  {
    return this.http.get(this.api + 'get_all_webapp_users_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getAdvertisementsForSelectedClientAdmin(dataObject)
  {
    return this.http.post(this.api + 'get_all_advertisements_for_selected_client_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addAdvertisementsAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_advertisements_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editAdvertisementsAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_advertisements_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }  

  deleteAdvertisementsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_advertisements_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }  
  
  //--Feedbacks--//
  getAllFeedbacksAdmin()
  {
    return this.http.get(this.api + 'get_all_feedbacks_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteFeedbacksAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_feedbacks_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    })
  }

  //--Vedic Cards--//
  
  getAllVedicCardsAdmin()
  {
    return this.http.get(this.api + 'get_all_vedic_cards_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addVedicCardsAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_vedic_cards_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editVedicCardsAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_vedic_cards_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteVedicCardsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_vedic_cards_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //--Transaction--//
  getAllTransactionsAdmin()
  {
    return this.http.get(this.api + 'get_all_transactions_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteTransactionsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_transactions_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getUserForSelectedTransactionAdmin(dataObject)
  {
    return this.http.post(this.api + 'get_user_for_selected_transaction_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //-- Packages --

  getAllPackagesAdmin()
  {
    return this.http.get(this.api + 'get_all_packages_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addPackagesAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_packages_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editPackagesAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_packages_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deletePackagesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_packages_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  // COURSES

  getAllCoursesAdmin()
  {
    return this.http.get(this.api + 'get_all_courses_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addCoursesAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_courses_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editCoursesAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_courses_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteCoursesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_courses_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  // ABOUT US

  getAllAboutUsAdmin()
  {
    return this.http.get(this.api + 'get_all_about_us_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addAboutUsAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_about_us_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editAboutUsAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_about_us_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteAboutUsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_about_us_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

   //--Myths Categories--//

   addMythsAdmin(dataObject)
   {
     return this.http.post(this.api + 'add_myths_category_admin',dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   getAllMythsAdmin()
   {
     return this.http.get(this.api + 'get_all_myths_category_admin',
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }
   
   editMythsAdmin(dataObject)
   {
     return this.http.post(this.api + 'edit_myths_category_admin',dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   deleteMythsAdmin(dataObject)
   {
     return this.http.post(this.api + 'delete_myths_category_admin',dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   //--Myths Articles--//

  getAllMythsArticlesForSelectedCategoryAdmin(dataObject)
  {
    return this.http.post(this.api + 'get_all_myths_articles_for_selected_category_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }

  addMythsArticlesAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_myths_articles_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editMythsArticlesAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_myths_articles_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }

  deleteMythsArticlesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_myths_articles_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }

  //--Myths Videos--//

  getAllMythsVideosForSelectedCategoryAdmin(dataObject)
  {
    return this.http.post(this.api + 'get_all_myths_videos_for_selected_category_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }

  addMythsVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_myths_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editMythsVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_myths_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }

  deleteMythsVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_myths_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    ) 
  }

  //--Disclaimers--//

  getAllDisclaimersAdmin()
  {
    return this.http.get(this.api + 'get_all_disclaimers_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addDisclaimersAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_disclaimers_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editDisclaimersAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_disclaimers_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteDisclaimersAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_disclaimers_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //--Fooled By Astrologers--//

  addFBAArticlesAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_fba_articles_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getAllFBAArticlesdmin()
  {
    return this.http.get(this.api + 'get_all_fba_articles_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  editFBAArticlesAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_fba_articles_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  deleteFBAArticlesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_fba_articles_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addFBAAVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_fba_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  getAllFBAVideosdmin()
  {
    return this.http.get(this.api + 'get_all_fba_videos_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  editFBAVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_fba_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  deleteFBAVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_fba_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addAstrologyVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_astrology_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  getAllAstrologyVideosAdmin()
  {
    return this.http.get(this.api + 'get_all_astrology_videos_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  editAstrologyVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_astrology_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  deleteAstrologyVideosAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_astrology_videos_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getAllPrivateLessonRegistrationsAdmin()
  {
    return this.http.get(this.api + 'get_all_private_lesson_registration_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  deletePLRegistrationsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_private_lesson_registration_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }
  setTimingPLRAdmin(object_3)
  {
    return this.http.post(this.api + 'edit_private_lesson_registration_admin',object_3,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getParticularWebappUserAdmin(dataObject)
  {
    return this.http.post(this.api + 'get_particular_webapp_user_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  showYearChartAdmin(dataObject)
  {
    return this.http.post(this.api + 'numerologyAlgorithmFunctionYearNew',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  showMonthChartAdmin(dataObject)
  {
    return this.http.post(this.api + 'numerologyAlgorithmFunctionMonthNew',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  showDateChartAdmin(dataObject)
  {
    return this.http.post(this.api + 'numerologyAlgorithmFunctionDateNew',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //-- Prices --//
  getAllPricesAdmin()
  {
    return this.http.get(this.api + 'get_all_prices_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addPricesAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_prices_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editPricesAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_prices_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deletePricesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_prices_admin',dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  //--Appointment--//
  getAllAppointmentsAdmin()
  {
    return this.http.get(this.api + 'get_all_appointments_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteAppointmentAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_appointment_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    })
  }

  //--Logical Remedies--//
  getAllLogicalRemediesAdmin()
  {
    return this.http.get(this.api + 'get_all_logical_remedies_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteLogicalRemediesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_logical_remedies_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    })
  }

  addRemediesAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_logical_remedies_by_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editRemediesAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_logical_remedies_by_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteRemediesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_logical_remedies_by_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  getRemediesAdmin()
  {
    return this.http.get(this.api + 'get_logical_remedies_by_admin', 
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  
 

  //--My Lucky Gemstone--//
  getAllMyLuckyGemstonesAdmin()
  {
    return this.http.get(this.api + 'get_all_my_lucky_gemstone_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteMyLuckyGemstonesAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_my_lucky_gemstone_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    })
  }

   //-- App Settings --//
  getAllAppSettingsAdmin()
  {
    return this.http.get(this.api + 'get_all_app_settings_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  addAppSettingsAdmin(dataObject)
  {
    return this.http.post(this.api + 'add_app_settings_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editAppSettingsAdmin(dataObject)
  {
    return this.http.post(this.api + 'edit_app_settings_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }  

  deleteAppSettingsAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_app_settings_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

     //--Vedic And Western Categories--//

     addVedicAndWesternAdmin(dataObject)
     {
       return this.http.post(this.api + 'add_vaw_category_admin',dataObject,
       {
         headers: new HttpHeaders(
         {
           Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
         })
       }
       )
     }
  
     getAllVedicAndWesternAdmin()
     {
       return this.http.get(this.api + 'get_all_vaw_category_admin',
       {
         headers: new HttpHeaders(
         {
           Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
         })
       }
       )
     }
     
     editVedicAndWesternAdmin(dataObject)
     {
       return this.http.post(this.api + 'edit_vaw_category_admin',dataObject,
       {
         headers: new HttpHeaders(
         {
           Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
         })
       }
       )
     }
     
     deleteVedicAndWesternAdmin(dataObject)
     {
       return this.http.post(this.api + 'delete_vaw_category_admin',dataObject,
       {
         headers: new HttpHeaders(
         {
           Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
         })
       }
       )
     }
  
     //--Vedic And Western Articles--//
  
    getAllVedicAndWesternArticlesForSelectedCategoryAdmin(dataObject)
    {
      return this.http.post(this.api + 'get_all_vaw_articles_for_selected_category_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      ) 
    }
  
    addVedicAndWesternArticlesAdmin(dataObject)
    {
      return this.http.post(this.api + 'add_vaw_articles_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      )
    }
  
    editVedicAndWesternArticlesAdmin(dataObject)
    {
      return this.http.post(this.api + 'edit_vaw_articles_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      ) 
    }
  
    deleteVedicAndWesternArticlesAdmin(dataObject)
    {
      return this.http.post(this.api + 'delete_vaw_articles_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      ) 
    }
  
    //--Vedic And Western Videos--//
  
    getAllVedicAndWesternVideosForSelectedCategoryAdmin(dataObject)
    {
      return this.http.post(this.api + 'get_all_vaw_videos_for_selected_category_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      ) 
    }
  
    addVedicAndWesternVideosAdmin(dataObject)
    {
      return this.http.post(this.api + 'add_vaw_videos_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      )
    }
  
    editVedicAndWesternVideosAdmin(dataObject)
    {
      return this.http.post(this.api + 'edit_vaw_videos_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      ) 
    }
  
    deleteVedicAndWesternVideosAdmin(dataObject)
    {
      return this.http.post(this.api + 'delete_vaw_videos_admin',dataObject,
      {
        headers: new HttpHeaders(
        {
          Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
        })
      }
      ) 
    }

    //--Muhurat--//

   addMuhuratAdmin(dataObject)
   {
     return this.http.post(this.api + 'add_muhurat_admin', dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   getAllMuhuratAdmin()
   {
     return this.http.get(this.api + 'get_all_muhurat_admin',
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }
   
   editMuhuratAdmin(dataObject)
   {
     return this.http.post(this.api + 'edit_muhurat_admin', dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   deleteMuhuratAdmin(dataObject)
   {
     return this.http.post(this.api + 'delete_muhurat_admin',dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   //--Muhurat Videos--//

   addMuhuratVideosAdmin(dataObject)
   {
     return this.http.post(this.api + 'add_muhurat_videos_admin', dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }
  
   getAllMuhuratVideosAdmin()
   {
    return this.http.get(this.api + 'get_all_muhurat_videos_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  editMuhuratVideosAdmin(dataObject)
   {
     return this.http.post(this.api + 'edit_muhurat_videos_admin', dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   deleteMuhuratVideosAdmin(dataObject)
   {
     return this.http.post(this.api + 'delete_muhurat_videos_admin',dataObject,
     {
       headers: new HttpHeaders(
       {
         Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
       })
     }
     )
   }

   //--Muhurat Request--//

  getAllMuhuratRequestAdmin()
  {
    return this.http.get(this.api + 'get_all_muhurat_request_admin',
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    }
    )
  }

  deleteMuhuratRequestAdmin(dataObject)
  {
    return this.http.post(this.api + 'delete_muhurat_request_admin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    })
  }

  //--Astrology Charts--//
  showAstrologyChartsAdmin(dataObject)
  {
    return this.http.post(this.api + 'getAstrologyChartsAdmin', dataObject,
    {
      headers: new HttpHeaders(
      {
        Authorization: JSON.parse(localStorage.getItem('auth_app_token')).value
      })
    })
  }
}



  



//auth_app_token{"name":"nb:auth:jwt:token","ownerStrategyName":"email","createdAt":1594756214047,"value":"JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1ZjQ5MTg3ZWI0ZWE0MTYzYTQ5ZTc0OTAiLCJuYW1lX2FkbWluIjoiQWRtaW4gQXN0cm8iLCJlbWFpbF9pZF9hZG1pbiI6ImFkbWluMUBnbWFpbC5jb20iLCJwaG9uZU51bWJlcl9hZG1pbiI6IjEyMzQ1Njc4OTAiLCJwYXNzd29yZF9hZG1pbiI6IiQyYiQxMCRIMmlWbE5XR2xHaTRXOWhBMFdsclFPVm1NbHd2ZHduSzdYczQuWERHLnFYbkNqVlRGcERNYSIsInJlRW50ZXJQYXNzd29yZF9hZG1pbiI6IiQyYiQxMCRIMmlWbE5XR2xHaTRXOWhBMFdsclFPVm1NbHd2ZHduSzdYczQuWERHLnFYbkNqVlRGcERNYSIsIl9fdiI6MH0.MbmnzZOQlKcU_X8vFLLOfK08vwYyBp3dUv4KY-Y2fQs"}
// test()
// {
//   return this.http.post<Object>(this.api+'add_services_admin',device,{headers: new HttpHeaders({
     
//     'Authorization': JSON.parse(localStorage.getItem('auth_app_token')).value
//   })});
// }

// {"name":"nb:auth:jwt:token","ownerStrategyName":"email","createdAt":1594756214047,"value":"JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1ZjI1ZDFkZjMzOWEyNmNlMWMyMzY2NWMiLCJuYW1lX2FkbWluIjoiSGl0ZXNoIFNlaGdhbCIsImVtYWlsX2lkX2FkbWluIjoiaGl0dS5zZWhnYWxAZ21haWwuY29tIiwicGhvbmVOdW1iZXJfYWRtaW4iOiI5ODc2NTQzMjExIiwicGFzc3dvcmRfYWRtaW4iOiIkMmIkMTAkN2NuakNwZEM3Q2ZmSlZLM05IUFdrdXF3SmJ1akdia0JNWmtGRDhQUk1QanVmQXdEaUNWSTYiLCJyZUVudGVyUGFzc3dvcmRfYWRtaW4iOiIkMmIkMTAkN2NuakNwZEM3Q2ZmSlZLM05IUFdrdXF3SmJ1akdia0JNWmtGRDhQUk1QanVmQXdEaUNWSTYiLCJfX3YiOjB9.2M9pPrsM2QL3LBvXkvOhTimy_SclMLJ5NfOlwD6AofQ"}