import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import {AstroAppServiceService} from './services/astro-app-service.service';
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private api:AstroAppServiceService,private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          console.log(authenticated);

          if (!authenticated) {
 localStorage.clear();
            this.router.navigate(['auth/login']);
           
          }
          else
          {
            this.api.authenticate_admin().subscribe(<Object>(data:any) =>{
          
        console.log(data);
        if(!data.success)
        {
           localStorage.clear();
           this.router.navigate(['auth/login']);
           
        }
    });
          }
        }),
      );
  }
}